import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import firebase from "firebase";
import VideocallUrl from "../VideocallUrl";
UIkit.use(Icons);

export default class ConsultAppointmentToday extends Component {
  state = {
    list: [],
    group: "self",
    loadingApi: false,
  };

  memberById = {};

  consultById = {};

  companyId = '';
  
  firebaseChatlistUpdate = (memberid, consultid) => {
    firebase.database().ref(`chatlist_m/${memberid}`).set(+new Date());
  }

  listGet = async () => {
    this.setState({ loadingApi: true });

    var { list, consult, member, companyid } = await Api.consult("AppointmentList", {
      dateStart: moment().startOf("d").format("YYYY-MM-DD 00:00:00"),
      dateStop: moment().endOf("d").format("YYYY-MM-DD HH:mm:ss"),
      group: this.state.group,
    });
     
    this.companyId = companyid;

    if (!list) list = [];
    if (!member) member = [];
    if (!consult) consult = [];

    member.forEach((data) => {
      this.memberById[data.id] = data;
    });

    consult.forEach((data) => {
      this.consultById[data.id] = data;
    });

    this.setState({
      list,
      loadingApi: false,
    });
  };
  
  logVideoCallSend = async (consultId, memberId, campaignId) => {
    var _r = await Api.member("LogVideoCallAdd", {
      consultId,
      memberId,
      campaignId,
      chatBy: 'consult'
    }); 


    var result = await Api.consult("ChatMessageAdd", {
      consultId: consultId,
      memberId: memberId,
      campaignId: campaignId,
      typeMessage: "text",
      memberRead: 0,
      consultRead: 1,
      message: `มีการเรียกระบบวีดีโอคอล กดลิงค์ เพื่อเข้าสู่หน้าวีดีโอคอล ` + VideocallUrl(this.companyId, memberId, consultId),
      chatby: 'c',
    });

    this.firebaseChatlistUpdate(memberId, consultId);
  };

  componentDidMount() { 
    this.listGet();
  }

  render() {
    return (
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">
          <div className="d-card-header-left">นัดหมายวันนี้</div>
          <div className="d-card-header-right">
            <Link className="uk-link-reset uk-text-small" to={"/consult/appointmentList"}>ดูตารางทั้งหมด</Link>
          </div>
        </div>
        <div>
          <table className="uk-table uk-table-small uk-table-divider">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่</th>
                <th>เวลา</th>
                <th>ชื่อคนไข้</th>
                <th>ผู้นัดหมาย</th>
                <th>นัดหมายเพื่อ</th>
                <th>หมายเหตุ</th>
              </tr>
            </thead>
            <tbody>
              {this.state.loadingApi === true ? (
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">
                      <span uk-spinner=""></span>
                    </div>
                  </td>
                </tr>
              ) : this.state.list.length === 0 ? (
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">
                      ไม่พบข้อมูล
                    </div>
                  </td>
                </tr>
              ) : (
                this.state.list.map((data, key) => {

                  var _member = this.memberById[data.memberid];

                  if (_member) {
                    _member = `${_member.nameprefix} ${_member.name} ${_member.lastname}`;
                  } else {
                    _member = "-";
                  }

                  var _consult = this.consultById[data.consultid];
                  if (_consult) {
                    _consult = `${_consult.nameprefix} ${_consult.name} ${_consult.lastname}`;
                  } else {
                    _consult = "-";
                  }
 
                  var _appointment_img = [];
                  if(data.image) {
                    try {
                      _appointment_img = JSON.parse(data.image);
                      _appointment_img = _appointment_img.map(()=>{return <span uk-lightbox="" className='uk-margin-small-right'><a href={_appointment_img}><img src={_appointment_img} style={{width: 50}} /></a></span>});
                    } catch (e) { 
                    }
                  }

                  return (
                    <tr key={`row${data.id}`}>
                      <td>{key + 1}</td>
                      <td>{moment(data.dateadd).format("DD/MM/YYYY")}</td>
                      <td>{moment(data.dateadd).format("HH:mm น.")}</td>
                      <td>{_member}</td>
                      <td>{_consult}</td>
                      <td className="uk-text-center">
                        <div>{data.name}</div>
                        <div>
                        <a
                          href={VideocallUrl(this.companyId, data.memberid, data.consultid, data.groupname)}
                          className="uk-margin-right"
                          target="_blank"
                          onClick={()=>{
                            this.logVideoCallSend(data.consultid, data.memberid, data.campaignid)
                          }}
                        >
                          กดวีดีโอคอล
                        </a>
                        <a
                          href={`/consult/chat?messageSelectIndex=ca${data.campaignid}_co${data.consultid}_m${data.memberid}`}
                        >
                          กดไปแชท
                        </a>

                        </div>
                      </td>
                      <td>
                        <div className='uk-margin-small-bottom'>{data.note} </div>
                        <div>{_appointment_img}</div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
