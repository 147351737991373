import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import Api from '../Api';
import firebase from "firebase";
import VideoCallBox from '../VideoCallBox';
import ImageUri from '../ImageUri';
import moment from 'moment';
import __ from '../Language.js';

export default class SideMenu extends Component {

  state = {
    showSideMenu: false,
    chatUnread: 0,
    videoCallShow: false,
    videoCallMemberId: null,
    videoCallConsultId: null,
    videoCallCampaignId: null,
    videoCallName: '',
    videoCallImage: '',
  }

  componentDidMount() {
    setInterval(()=>{
      this.unreadChatGet();
    }, 10000)

    
    this.firebaseListenUpdate();
 
  }

  
  chatListGet = async () => {
    var _d = await Api.member("ChatListV2", {
      campaignId: 'all',
      chatListPage: 1,
      keyword: "",
      filterChatTypeSelect: "all"
    });

    if(Array.isArray(_d.list)) {
      for(var data of _d.list) {
        if(data.chatLast != null && parseInt(data.unreadMember) > 0 && data.chatLast.name.includes('มีการเรียกระบบวีดีโอคอล') === true && moment().diff(moment(data.chatLast.dateadd), 'minutes') < 10 /** มีการเรียกวีดีโอคอลน้อยกว่า 10 นาที */) {

          this.setState({
            videoCallShow: true,
            videoCallName: `${data.nameprefix} ${data.name} ${data.lastname}`,
            videoCallImage: data.image,
            videoCallMemberId: data.memberid,
            videoCallConsultId: data.consultid,
            videoCallCampaignId: data.campaignid
          })

          return false;
        }
      }
    }

    delete _d.list;
  }

  unreadChatGet = async () => {
    var {amount} = await Api.member('ChatUnread');
    this.setState({chatUnread: amount || 0});
  }
  
  firebaseListenUpdate = () => {
    firebase.database().ref(`chatlist_m/${this.props.member.id}`).on('value', (snapshot) => {
      
      this.unreadChatGet();
      this.chatListGet();
    });
  }

  menuV2Render = () => {
    var _menu = [
      this.props.level == '1' && {
        label: 'Company',
        link: '',
        children: [
          {
            icon: 'home',
            label: 'Dashboard',
            link: '/dashboard'
          },
          {
            icon: 'screen-desktop',
            label: 'Trending',
            link: '/smart-health'
          },
          {
            icon: 'notebook',
            label: 'Campaign',
            link: '/campaign-report'
          },
          {
            icon: 'film',
            label: 'Report',
            link: '/report'
          },
          {
            icon: 'people',
            label: 'Find people',
            link: '/people'
          },
        ]
      },
      {
        label: 'User',
        link: '',
        children: [
          {
            icon: 'graph',
            label: 'Smart Health',
            link: '/user-smart-health'
          },
          {
            icon: 'user',
            label: 'Profile',
            link: '/user'
          },
          {
            icon: 'bubble',
            label: __('Chat'),
            link: '/chat'
          },
          {
            icon: 'camrecorder',
            label: __('Video call'),
            link: '/chat'
          },
          {
            icon: 'note',
            label: 'ใบสั่งยาของฉัน',
            link: '/prescriptionList'
          },
          {
            icon: 'note',
            label: 'ใบเสร็จของฉัน',
            link: '/invoiceList'
          },
          {
            icon: 'event',
            label: 'ตารางนัด',
            link: '/appointment-list'
          },
          {
            icon: 'pie-chart',
            label: 'สรุปผลสุขภาพ',
            link: '/health-summary'
          },
          {
            icon: 'film',
            label: 'สมุดสุขภาพ',
            link: '/work-health-record-list'
          },
          {
            icon: 'support',
            label: 'Wearable Device',
            link: '/wearable-device'
          },
        ]
      },
      
      {
        label: 'Customer Service',
        link : 'https://dietz.asia/',
        target: '_blank',
        children: [
          {
            icon: 'speech',
            label: 'แบบสำรวจความพึงพอใจ',
            target: '_blank',
            link: '/survey'
            //link: "https://docs.google.com/forms/d/e/1FAIpQLSdNwjjyNC1leSQ0gzGUFfI6aZ9sBOfBWvcjkEUuhEvH3EzU9w/viewform"
          },
          /*
          
          {
            icon: 'cloud-download',
            label: 'Download Stress Check',
            target: '_blank',
            link: "https://smarthealth.dietz.asia/r'KN=ypgr=ily'ski/download.html"
          },
          
          {
            icon: 'cloud-upload',
            label: 'Upload Stress Check Result',
            target: '_blank',
            link: 'https://dietz.asia/form/Stress_Test.php'
          },
          */
        ]
      }
      
    ];

    var _nowpath = window.location.pathname;

    return <ul className="uk-list">{
      _menu.map((data, key) => {


        return <li key={'sideMenuPrimary' + key}>
            <div>
            {
              data.link ? 
              <a className="d-side-menu-title" href={data.link} target={data.target || '_self'} rel="_noopener">{data.label}</a> 
              :
            <div className="d-side-menu-title">{data.label}</div> 
            }
            </div>
            {
              data.children && data.children.length ? 
              <div>
                {
                  data.children.map((menu, menuKey) => {


                  var _noti = null;

                  if(menu.label === 'Chat') {
                    _noti = +this.state.chatUnread > 0 ? <span className="uk-badge uk-float-right uk-button-danger" >{this.state.chatUnread}</span> : null
                  }
                    
                  var _text = <div>
                      <span className={'icon-' + menu.icon}></span>{' '}
                      <span className="d-side-menu-show-hover">{_noti} {menu.label} </span>
                  </div>

                    return <div key={'menu'+key+'_'+menuKey}>
                      {
                        menu.link.indexOf('http') >= 0 ? 
                        <a className="d-font-mon" href={menu.link} target={menu.target || '_self'} rel="noopener">{_text}</a>
                        :
                        <Link to={menu.link} className={'d-font-mon ' + (_nowpath === '/' ? (menu.link === '/chat' ? 'd-side-menu-active' : '') : (menu.link === _nowpath ? 'd-side-menu-active' : ''))} onMouseDown={(e) => {
                          if (menu.link === '/campaign-report') {
                            e.stopPropagation();
                            window.location.href = '/campaign-report';
                          }
                        }}
                        onClick={() => this.setState({ showSideMenu: false })}
                        >{_text}</Link>
                      }
                    </div>
                  })
                }
              </div>
              :null

            }
        </li>
      })
    }
    </ul>
  }

  menuRender = () => {
    var menu = [
      {
        icon: 'user',
        label: 'User',
        link: '/user-smart-health'
      },
      {
        icon: 'bubble',
        label: 'Chat/Video call',
        link: '/chat'
      },/*
      {
        icon: 'note',
        label: 'Dietz Content',
        link: '/dietz-content'
      }*/
    ];

    if (this.props.level == '1') {
      menu = [
        {
          icon: 'home',
          label: 'Dashboard',
          link: '/dashboard'
        },
        {
          icon: 'screen-desktop',
          label: 'Smart Health',
          link: '/smart-health'
        },
        {
          icon: 'notebook',
          label: 'Campaign Report',
          link: '/campaign-report'
        },
        ...menu
      ]
    }

    var _nowpath = window.location.pathname;

    return <ul className="uk-list">{
      menu.map((data, key) => {

        var _noti = null;

        if(data.label === 'Chat') {
          _noti = +this.state.chatUnread > 0 ? <span className="uk-badge uk-float-right uk-button-danger" >{this.state.chatUnread}</span> : null
        }

        var _text = <div>
            <span className={'icon-' + data.icon}></span>{' '}
            <span className="d-side-menu-show-hover">{_noti} {data.label} </span>
        </div>

        return <li key={'sideMenu' + key}>
          {
            data.type === 'a' ?
            <a className="d-font-mon" href={data.link} target="_blank">{_text}</a>
            :
            <Link to={data.link} className={'d-font-mon ' + (data.link === '/' ? (data.link === _nowpath ? 'd-side-menu-active' : '') : (_nowpath.indexOf(data.link) >= 0 ? 'd-side-menu-active' : ''))} onMouseDown={() => {
              if (data.link === '/campaign-report') {
                window.location.href = '/campaign-report';
              } else {
                return false;
              }
            }}
              onClick={() => this.setState({ showSideMenu: false })}
            >{_text}</Link>
          }
        </li>
      })
    }
    </ul>
  }

  render() { 
    return <div>
      {this.state.videoCallShow === true && <VideoCallBox 
      apiType="member" 
      companyId={this.props.member?.company?.companyid}
      campaignId={this.state.videoCallCampaignId} 
      consultId={this.state.videoCallConsultId} 
      memberId={this.state.videoCallMemberId} 
      name={this.state.videoCallName} 
      buttonTitle='คุยกับหมอ' 
      image={this.state.videoCallImage} 
      closeCallback={()=>{
        this.setState({videoCallShow: false});
      }}
    />}
      {this.state.showSideMenu && <div id="d-side-menu-overlay" className="uk-animation-fade" onClick={() => this.setState({ showSideMenu: false })}></div>}
      <div id="d-header-menu-mobile" uk-sticky="">
        <div className="uk-grid-collapse" uk-grid="">
          <div className="uk-width-1-4">
            <a className="d-header-menu-mobile-button" onClick={() => {
              this.setState({ showSideMenu: !this.state.showSideMenu });
            }}>
              <i className="icon-menu"></i>
            </a>
          </div>
          <div className="uk-width-1-2 uk-flex-middle uk-text-center">
            {this.props.member.company && this.props.member.company.image && <img src={ImageUri(this.props.member.company.image)} className="uk-margin-small-right" width={40} /> }
            <img src="/assets/stack-logo-light.png" className="uk-margin-small-right" width={20} />
            <img src="/assets/logo_dietz.png" width={60} />
          </div>
          <div className="uk-width-1-4 uk-text-right">
            <a className="d-header-menu-mobile-button" onClick={() => {
              
              setTimeout(function() {
                $("#d-header-menu").toggleClass('d-header-menu-active uk-animation-slide-top-small')
              }, 50);
            }}>
              <i className="icon-options-vertical"></i>
            </a>
          </div>
        </div>
      </div>
      <div className={"d-side-menu " + (this.state.showSideMenu ? 'd-side-menu-active' : '')}>
        <div className="d-side-menu-logo-container uk-flex-middle">
            {this.props.member.company && this.props.member.company.image && <img src={ImageUri(this.props.member.company.image)} className="uk-margin-small-right" width={40} />}
            <img src="/assets/stack-logo-light.png" className="uk-margin-small-right" width={20} />
            <img src="/assets/logo_dietz.png" width={60} />
        </div>
        {this.props.level == '1' && <div className="d-side-menu-title-container d-font-mon d-side-menu-show-hover">
          GENERAL
        </div>
        }
        {this.menuV2Render()}
      </div>
    </div>
  }
};
