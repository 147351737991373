import React, { Component } from "react";
import Api from "../../Components/Api.js";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import ImageUri from "../../Components/ImageUri.js";
import UploadFirebase from "../../Components/UploadFirebase.js";
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";
UIkit.use(Icons);

export default class ArticleAdd extends Component {
  state = {
    loading: true,
    lastUpdate: 0,
    notfound: false,
  };

  form = {
    title: null,
    description: null,
    detail: null,
    publish: null,
    image: null,
  };

  value = {};

  editor = null;

  componentDidMount() {
    this.ArticleGet();
  }

  ArticleGet = async () => {
    if (this.props.qs && this.props.qs.id) {
      var _response = await Api.consult("M2ArticleGet", {
        id: this.props.qs.id,
      });

      this.value = _response.data || {};

      for (var key in this.form) {
        if (key === "detail") {
          //
        } else if (key === "image") {
          this.form[key] = this.value[key];
        } else if (key === "publish") {
          this.form[key].checked = this.value[key] == "1" ? true : false;
        } else {
          this.form[key].value = this.value[key] || "";
        }
      }

      this.setState({
        loading: false,
        notfound: _response.data == null,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  ArticleUpdate = async () => {
    var _data = this.ValueGet();

    if (this.props.qs && this.props.qs.id) {
      _data.id = this.props.qs.id;
    }

    var _response = await Api.consult(
      _data.id ? "M2ArticleUpdate" : "M2ArticleAdd",
      _data
    );
 

    UIkit.notification(_response.responseText);
    //
    if (_response.id) {
      window.location.href = `/consult/articleAdd?id=${_response.id}`;
    }
  };

  ArticleRemove = async () => {
    if (window.confirm("ยืนยันการลบข้อมูล" + this.form.title.value + "?")) {
      var _response = await Api.consult("M2ArticleRemove", {
        id: this.props.qs.id,
      });

      UIkit.notification(_response.responseText);

      if (_response.result === true) {
        window.location.href = "/consult/articleList";
      }
    }
  };

  ValueGet = () => {
    var _value = {};
    for (var key in this.form) {
      if (key === "detail") {
        _value[key] = this.form.detail.getContent();
      } else if (key === "publish") {
        _value[key] = this.form[key].checked === true ? "1" : "0";
      } else if (key === "image") {
        _value[key] = this.form[key];
      } else {
        _value[key] = this.form[key].value;
      }
    }

    return _value;
  };

  render() {
    return (
      <div className="uk-padding">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">จัดการบทความ</div>
          {this.state.notfound === true ? (
            <div className="uk-padding uk-text-center">ไม่พบข้อมูล</div>
          ) : (
            <div className={this.state.loading === true ? `uk-hidden` : ""}>
              <form>
                <label className="uk-width-1-1 uk-margin-top">
                  <div>ชื่อบทความ</div>
                  <input
                    type="text"
                    className="uk-input uk-margin-small-top uk-text-normal"
                    placeholder="ชื่อบทความ"
                    ref={(ref) => {
                      this.form.title = ref;
                    }}
                  />
                </label>
                <label className="uk-width-1-1 uk-margin-top">
                  <div>คำอธิบายบทความเบื้องต้น</div>
                  <input
                    type="text"
                    className="uk-input uk-margin-small-top uk-text-normal"
                    placeholder="คำธิบายเบื้องต้น"
                    ref={(ref) => {
                      this.form.description = ref;
                    }}
                  />
                </label>
                <div className="uk-margin-top">
                  {this.form.image && (
                    <div>
                      <img
                        src={ImageUri(this.form.image)}
                        height={150}
                        className="uk-margin-bottom uk-img"
                      />
                    </div>
                  )}
                  <label className="uk-button uk-button-default">
                    <div>เลือกรูปภาพ</div>

                    <UploadFirebase
                      maxWidth={2000}
                      maxHeight={2000}
                      accept={"image/*"}
                      cb={(file) => {
                        this.form.image = file;

                        this.setState({ lastUpdate: +new Date() });
                      }}
                    />
                  </label>
                </div>
                <div className="uk-margin-top">
                  <Editor
                    tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.8.2/tinymce.min.js"
                    onInit={(evt, editor) => {
                      this.form.detail = editor;
                    }}
                    initialValue={this.value.detail}
                    init={{
                      height: 500,
                      menubar: true,
                      branding: false,
                      promotion: false,
                      remove_script_host: true,
                      draggable_modal: true,
                      placeholder: "รายละเอียดบทความ",
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      setup: (editor) => {
                        this.editor = editor;

                        editor.ui.registry.addButton("dietz-media", {
                          icon: "gallery",
                          onAction: () => {
                            $("#tinymce-dietz-upload-button").click();
                          },
                        });
                      },
                      toolbar:
                        "undo redo | forecolor backcolor | bold italic link ww-mediabox hr | styleselect fontsizeselect | fontselect | removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | dietz-media image media code | fullscreen",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  <label id="tinymce-dietz-upload-button">
                    <UploadFirebase
                      maxWidth={2000}
                      maxHeight={2000}
                      accept={"image/*"}
                      name="tinymce-dietz-upload"
                      cb={(file) => {
                        this.editor.execCommand(
                          "mceInsertContent",
                          false,
                          `<img src="${ImageUri(file)}" class="uk-img" />`
                        );
                      }}
                    />
                  </label>
                </div>
                <label className="uk-margin-top uk-margin-bottom">
                  <input
                    type="checkbox"
                    className="uk-checkbox"
                    ref={(ref) => {
                      this.form.publish = ref;
                    }}
                  />{" "}
                  <span>เปิดใช้งาน</span>
                </label>
                <div className="uk-grid uk-margin-top">
                  <div className="uk-width-1-1 uk-width-1-2@m">
                    {this.props.qs && this.props.qs.id && (
                      <a
                        onClick={this.ArticleRemove}
                        className="uk-text-danger uk-text-underline uk-margin-top"
                      >
                        <u>
                          <span uk-icon="trash"></span> ลบบทความ
                        </u>
                      </a>
                    )}
                  </div>
                  <div className="uk-width-1-1 uk-width-1-2@m uk-text-right">
                    <button
                      type="button"
                      className="uk-button uk-button-primary"
                      onClick={this.ArticleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}
