export default (companyid, memberid, consultid, groupname) => {  
  if(groupname) {
    if(companyid == '127') { //มหาชัย2
      return `https://tele1-1.dietz.asia/${groupname}?openExternalBrowser=1`;
    }
    else {
      return `https://con.defence-innovation.com/${groupname}?openExternalBrowser=1`;
  
    } 

  } else {
    if(companyid == '127') { //มหาชัย2
      return `https://tele1-1.dietz.asia/${memberid}/${consultid}?openExternalBrowser=1`;
    }
    else {
      return `https://con.defence-innovation.com/${memberid}/${consultid}?openExternalBrowser=1`;
  
    } 

  }
};