import React, { Component } from "react";
import Api from "../../Components/Api.js";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import ImageUri from "../../Components/ImageUri.js";
import UploadFirebase from "../../Components/UploadFirebase.js";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
UIkit.use(Icons);

export default class PackageAdd extends Component {
  state = {
    loading: true,
    lastUpdate: 0,
    notfound: false,
    form_price_array_title: '',
    form_price_array_price: '',
  };

  form = {
    title: null,
    description: null,
    detail: null,
    price: null,
    publish: null,
    image: null,
    price_type: null,
    price_array: null
  };

  value = {}

  editor = null;

  componentDidMount() {
    this.PackageGet();
  }

  PackageGet = async () => {
    if(this.props.qs && this.props.qs.id) {

      var _response = await Api.consult('M2PackageGet',{id: this.props.qs.id});

      console.log(_response);

      this.value = _response.data || {}

        for(var key in this.form) {
          if(key === 'detail') {
            //
          } 
          else if(key === 'image') {
            this.form[key] = this.value[key];
          }
          else if(key === 'publish') {
            this.form[key].checked = this.value[key] == '1' ? true : false;
          } else if(this.form[key] != null && this.value[key] != null) {
              this.form[key].value = this.value[key] || '';
          }
        }

        this.form.price_type = _response.data.price_type;

        try {
          this.form.price_array = JSON.parse(decodeURIComponent(_response.data.price_array));

        } catch(e) {
          console.error(e)
          this.form.price_array = [];
        }



        this.setState({
          loading: false,
          notfound: _response.data == null 
        })
    } else {


      this.setState({
        loading: false,
      })
    }
    

  }

  PackageUpdate = async () => {
    var _data = this.ValueGet();

    if(this.props.qs && this.props.qs.id) {
      _data.id = this.props.qs.id;
    }


    var _response = await Api.consult( (_data.id ? 'M2PackageUpdate' : 'M2PackageAdd'), _data);


    UIkit.notification(_response.responseText);
    //
    if(_response.id) {
      window.location.href = `/consult/packageAdd?id=${_response.id}`;
    } 
  }

  PackageRemove = async () => {
    if(window.confirm('ยืนยันการลบข้อมูล' + this.form.title.value + '?')) {
      var _response = await Api.consult('M2PackageRemove', {
        id: this.props.qs.id
      });

      UIkit.notification(_response.responseText);

      if(_response.result === true) {
        window.location.href = '/consult/packageList';
      }
    }
  }

  ValueGet = () => {
    var _value = {};
    for(var key in this.form) {
      if(key === 'detail') {
        _value[key] = this.form.detail.getContent();
      } 
      else if(key === 'publish') {
        _value[key] =  this.form[key].checked === true ? '1' : '0';
      }
      else if(key === 'image') {
        _value[key] = this.form[key];
      }
      else if(this.form[key] != null) {
        _value[key] = this.form[key].value;
      }
    }

    if(this.form.price_array === null) {
      this.form.price_array = [];
    }

    _value.price_array = encodeURIComponent(JSON.stringify(this.form.price_array));

    _value.price_type = this.form.price_type;

    return _value;
  }

  PriceArrayAdd = () => {
    if(!Array.isArray(this.form.price_array)) {
      this.form.price_array = [];
    }

    this.form.price_array.push({
      title: this.state.form_price_array_title,
      price: this.state.form_price_array_price,
    });
    
    this.setState({
      form_price_array_price: '',
      form_price_array_title: ''
    })
  }

  render() {
    return (
      <div className="uk-padding">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">จัดการแพ็คเกจ</div>
          {
            this.state.notfound === true ?
            <div className="uk-padding uk-text-center">ไม่พบข้อมูล</div>
            :
            <div className={this.state.loading === true ? `uk-hidden` : ''}>
            <form>
              <label className="uk-width-1-1 uk-margin-top">
                <div>ชื่อแพ็คเกจ</div>
                <input
                  type="text"
                  className="uk-input uk-margin-small-top uk-text-normal"
                  placeholder="ชื่อแพ็คเกจ"
                  ref={(ref)=>{
                    this.form.title = ref;
                  }}
                />
              </label>
              <label className="uk-width-1-1 uk-margin-top">
                <div>คำอธิบายแพ็คเกจเบื้องต้น</div>
                <input
                  type="text"
                  className="uk-input uk-margin-small-top uk-text-normal"
                  placeholder="คำธิบายเบื้องต้น"
                  ref={(ref)=>{
                    this.form.description = ref;
                  }}
                />
              </label>
              <div className="uk-margin-top">
                {this.form.image && <div><img src={ImageUri(this.form.image)} height={150} className="uk-margin-bottom uk-img" /></div>}
                <label className="uk-button uk-button-default">
                  <div>เลือกรูปภาพ</div>

                  <UploadFirebase
                    maxWidth={2000}
                    maxHeight={2000}
                    accept={"image/*"}
                    cb={(file) => {
                      this.form.image = file;

                      this.setState({lastUpdate: +new Date})
                    }}
                  />
                </label>
              </div>
              <div className="uk-margin-top">
                <Editor
                  tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.8.2/tinymce.min.js"
                  onInit={(evt, editor) => {
                    this.form.detail = editor;
                  }}
                  initialValue={this.value.detail}
                  init={{
                    height: 500,
                    menubar: true,
                    branding: false,
                    promotion: false,
                    remove_script_host: true,
                    draggable_modal: true,
                    placeholder: "รายละเอียดแพ็คเกจ",
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "code",
                      "help",
                      "wordcount",
                    ],
                      setup: (editor) => {
                        this.editor = editor;

                        editor.ui.registry.addButton("dietz-media", {
                          icon: "gallery",
                          onAction: () => {
                            $("#tinymce-dietz-upload-button").click();
                          },
                        });
                      },
                    toolbar:
                      "undo redo | forecolor backcolor | bold italic link ww-mediabox hr | styleselect fontsizeselect | fontselect | removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | dietz-media image media code | fullscreen",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
                <label id="tinymce-dietz-upload-button">
                  <UploadFirebase
                    maxWidth={2000}
                    maxHeight={2000}
                    accept={"image/*"}
                    name="tinymce-dietz-upload"
                    cb={(file) => {
                      this.editor.execCommand(
                        "mceInsertContent",
                        false,
                        `<img src="${ImageUri(file)}" class="uk-img" />`
                      );
                    }}
                  />
                </label>
              </div>
              <div>
              <label> 
                <input type="radio" name="price-type" value="" checked={this.form.price_type !== 'multi'} onChange={(e)=>{
                  this.form.price_type = '';
                  this.setState({lastUpdate: +new Date})
                }} /> ใช้รูปแบบราคาเดียว
              </label>
              </div>
              {
                this.form.price_type !== 'multi' &&
                <label className="uk-width-1-1 uk-margin-top">
                  <div>ราคา</div>
                  <input
                    type="number"
                    className="uk-input uk-margin-small-top uk-text-normal"
                    placeholder="0"
                    ref={(ref)=>{
                      this.form.price = ref;
                    }}
                  />
                </label>
              }
              <div>
              <label>
                <input type="radio" name="price-type" value="multi" checked={this.form.price_type === 'multi'} onChange={(e)=>{
                  this.form.price_type = 'multi';
                  this.setState({lastUpdate: +new Date});
                }} /> ใช้รูปแบบหลายราคา
              </label>
              </div>
              {
                this.form.price_type === 'multi' && <div>
                  <div className="uk-margin-small-top uk-text-bold">เพิ่มรายการ</div>
                  <form onSubmit={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();

                    this.PriceArrayAdd();

                  }}
                  className="uk-grid uk-margin-small-top uk-child-width-1-3@m uk-child-width-1-1"
                  >
                    <div>
                      <input type="text" className="uk-input uk-width-1-1" required={true} placeholder="ชื่อสินค้า" value={this.state.form_price_array_title} onChange={(e)=>{
                        this.setState({form_price_array_title: e.target.value});
                      }} />
                    </div>
                    <div>
                      <input type="number" className="uk-input uk-width-1-1" required={true} placeholder="ราคา" min="1" value={this.state.form_price_array_price} onChange={(e)=>{
                        this.setState({form_price_array_price: e.target.value});
                      }} />
                    </div>
                    <div>
                      <button type="submit" className="uk-button uk-button-muted">เพิ่มราคา</button>
                    </div>
                  </form>
                  {
                    Array.isArray(this.form.price_array) === true && <div>
                      <div className="uk-margin-top"><u>รายการราคา</u></div>
                      {
                        this.form.price_array.map((d, index)=>{
                          return <div className="uk-grid uk-grid-margin uk-margin-small-top  uk-card uk-child-width-1-3@m uk-child-width-1-1">
                            <div>
                              <input className="uk-input uk-width-1-1" required={true} placeholder="ชื่อสินค้า" type="text" value={d.title} onChange={(e)=>{
                                d.title = e.target.value;
                                this.setState({lastUpdate: +new Date});
                              }} />
                            </div>
                            <div>
                              <input className="uk-input uk-width-1-1" required={true} placeholder="ราคา" type="number" min="1" value={d.price} onChange={(e)=>{
                                d.price = e.target.value;
                                this.setState({lastUpdate: +new Date});
                              }} />
                            </div>
                            <div>
                              <button type="button" className="uk-button uk-button-danger" onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();

                                delete this.form.price_array[index];

                                this.form.price_array = this.form.price_array.filter(Boolean);

                                this.setState({
                                  lastUpdate: + new Date
                                })
                              }}>ลบ</button>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  }
                </div>
              }
              <div>
              <label className="uk-margin-top uk-margin-bottom">
                <input 
                  type="checkbox" 
                  className="uk-checkbox"
                  ref={(ref)=>{
                    this.form.publish = ref;
                  }} /> <span>เปิดใช้งาน</span>
              </label>
              </div>
              <div className="uk-grid uk-margin-top">
                <div className="uk-width-1-1 uk-width-1-2@m">
                  { this.props.qs && this.props.qs.id && <a onClick={this.PackageRemove} className="uk-text-danger uk-text-underline uk-margin-top"><u><span uk-icon="trash"></span> ลบแพ็คเกจ</u></a>}
                </div>
                <div className="uk-width-1-1 uk-width-1-2@m uk-text-right">
                <button type="button" className="uk-button uk-button-primary" onClick={this.PackageUpdate}>Save</button>

                </div>
              </div>
            </form>
          </div>
          }
          
        </div>
      </div>
    );
  }
}
