import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import numeral from 'numeral';
import $ from 'jquery';

UIkit.use(Icons);

export default class RegisterTableByYear extends Component {

  state = {
    data_header: [],
    data_row: [],
    year: 'all',
    campaign: 'all',
    filter: 'all'
  }

  list = [];

  listByCampaignId = {};

  componentDidMount() {
    this.init();
  }

  async init() {
    await this.dateGet();
    this.dataRender();
  }

  dateGet = async () => {
    var _response = await Api.moderator("RegisterTableByYear");

    this.list = _response.list;
  }

  dataRender = () => {
    var _data_format = {
      amount: 0,
      gender_0: 0,
      gender_1: 0,
      age_0: 0,
      age_18: 0,
      age_30: 0,
      age_40: 0,
      age_50: 0,
      age_60: 0,
      age_70: 0,
      error_0: 0,
      error_80: 0,
      error_all: 0
    }

    var _data_amount_render = (now_data, all) => {
      all.amount++;

      if (now_data.gender === '1') {
        all.gender_1++;
      } else {
        all.gender_0++;
      }

      var _age = moment().diff(now_data.birthday, 'years');

      if (_age < 18) {
        all.age_0++;
        all.error_0++;
        all.error_all++;

      } else if (_age < 30) {
        all.age_30++;

      } else if (_age < 40) {
        all.age_40++;

      } else if (_age < 50) {
        all.age_50++;

      } else if (_age < 60) {
        all.age_60++;

      } else if (_age < 70) {
        all.age_70++;

      } else {
        all.error_80++;
        all.error_all++;
      }
    }

    for (var d of this.list) {
      if (this.listByCampaignId[d.campaignid] == null) {
        this.listByCampaignId[d.campaignid] = {
          label: d.campaign_name,
          year: {
            all: { ..._data_format }
          }
        }
      }

      _data_amount_render(d, this.listByCampaignId[d.campaignid].year.all);

      //

      var _year = moment(d.dateadd, 'YYYY-MM-DD HH:mm:ss').format('YYYY');

      if (this.listByCampaignId[d.campaignid].year[_year] == null) {
        this.listByCampaignId[d.campaignid].year[_year] = { ..._data_format }
      }


      _data_amount_render(d, this.listByCampaignId[d.campaignid].year[_year]);
    }


    var _template_row_1 = [
      <th rowSpan={2}>จำนวนทั้งหมด (ราย)</th>,
      <th colSpan={2}>จำนวนตามเพศกำเนิด</th>,
      <th colSpan={9}>ช่วงอายุ (ราย)</th>
    ];

    var _template_row_2 = [
      <th>ชาย (ราย)</th>,
      <th>หญิง (ราย)</th>,
      <th>18 - 29 ปี</th>,
      <th>30 - 39 ปี</th>,
      <th>40 - 49 ปี</th>,
      <th>50 - 59 ปี</th>,
      <th>60 - 69 ปี</th>,
      <th>70 - 79 ปี</th>,
      <th>ERROR (อายุ 0 - 17 ปี)</th>,
      <th>ERROR (อายุมากกว่า 80 ปี)</th>,
      <th>ERROR (รวม)</th>
    ];

    //
    var _data_header_row_0 = [
      <th></th>
    ];
    var _data_header_row_1 = [
      <th>ข้อมูลทั่วไปของผู้รับบริการ Telehealth</th>,
    ];

    var _data_header_row_2 = [
      <th></th>
    ];

    ///All

    _data_header_row_0.push([
      <td colSpan={12}>{this.state.year === 'all' ? 'รวมทุกปี' : this.state.year}</td>
    ]);
    _data_header_row_1.push(..._template_row_1);
    _data_header_row_2.push(..._template_row_2)


    ///

    // var _start_year = 2022;
    // var _now_year = moment().format('YYYY');

    // for(var i=_start_year; i<=_now_year; i++) {
    //   _data_header_row_0.push([
    //     <td colSpan={12}>ปี {i + 543} (ม.ค. - ธ.ค.)</td>
    //   ]);

    //   _data_header_row_1.push(..._template_row_1);

    //   _data_header_row_2.push(..._template_row_2)
    // }


    var _data_header = [
      <tr>
        {_data_header_row_0}
      </tr>,
      <tr>
        {_data_header_row_1}
      </tr>,
      <tr>
        {_data_header_row_2}
      </tr>
    ];

    //
    var _data_row = [];
    for (var camid in this.listByCampaignId) {
      if(this.state.filter !== 'all') {
        if(this.state.filter.includes(camid) !== true) {
          continue;
        }
      }

      var _d = this.listByCampaignId[camid];

      var _col = [
        <td>{_d.label}</td>
      ];

      ///All
      var _dy = _d.year[this.state.year];

      if (_dy != null) {
        _col.push([
          <td><b>{numeral(_dy.amount).format('0,0')}</b></td>,
          <td>{numeral(_dy.gender_1).format('0,0')}</td>,
          <td>{numeral(_dy.gender_0).format('0,0')}</td>,
          <td>{numeral(_dy.age_18).format('0,0')}</td>,
          <td>{numeral(_dy.age_30).format('0,0')}</td>,
          <td>{numeral(_dy.age_40).format('0,0')}</td>,
          <td>{numeral(_dy.age_50).format('0,0')}</td>,
          <td>{numeral(_dy.age_60).format('0,0')}</td>,
          <td>{numeral(_dy.age_70).format('0,0')}</td>,
          <td>{numeral(_dy.error_0).format('0,0')}</td>,
          <td>{numeral(_dy.error_80).format('0,0')}</td>,
          <td>{numeral(_dy.error_all).format('0,0')}</td>
        ])

      } else {
        _col.push([
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>,
          <td>-</td>
        ])
      }

      ///
      // for(var i=_start_year; i<=_now_year; i++) {
      //   if(_d.year[i] != null) {
      //     var _dy = _d.year[i];
      //     _col.push([
      //       <td><b>{numeral(_dy.amount).format('0,0')}</b></td>,
      //       <td>{numeral(_dy.gender_1).format('0,0')}</td>,
      //       <td>{numeral(_dy.gender_0).format('0,0')}</td>,
      //       <td>{numeral(_dy.age_18).format('0,0')}</td>,
      //       <td>{numeral(_dy.age_30).format('0,0')}</td>,
      //       <td>{numeral(_dy.age_40).format('0,0')}</td>,
      //       <td>{numeral(_dy.age_50).format('0,0')}</td>,
      //       <td>{numeral(_dy.age_60).format('0,0')}</td>,
      //       <td>{numeral(_dy.age_70).format('0,0')}</td>,
      //       <td>{numeral(_dy.error_0).format('0,0')}</td>,
      //       <td>{numeral(_dy.error_80).format('0,0')}</td>,
      //       <td>{numeral(_dy.error_all).format('0,0')}</td>
      //     ])
      //   } else {
      //     _col.push([
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>,
      //       <td>-</td>
      //     ])
      //   }
      // }

      _data_row.push(
        <tr key={'row-' + camid}>{_col}</tr>
      )
    }

    this.setState({
      data_header: _data_header,
      data_row: _data_row
    });
  }

  CsvExport = () => {

    var _h_row_0 = `"","${this.state.year === 'all' ? 'รวมทุกปี' : this.state.year}","","","","","","","","","","",""`;
    var _h_row_1 = `"ข้อมูลทั่วไปของผู้รับบริการ Telehealth","จำนวนทั้งหมด (ราย)","จำนวนตามเพศกำเนิด (ราย)","","ช่วงอายุ (ราย)","","","","","","","",""`;
    var _h_row_2 = `"","","ชาย (ราย)","หญิง (ราย)","18 - 29 ปี","30 - 39 ปี","40 - 49 ปี","50 - 59 ปี","60 - 69 ปี","70 - 79 ปี","ERROR (อายุ 0 - 17 ปี)","ERROR (อายุมากกว่า 80 ปี)","ERROR (รวม)"`;


    // var _start_year = 2022;
    // var _now_year = moment().format('YYYY');

    // for (var i = _start_year; i <= _now_year; i++) {

    //   _h_row_0 += `,"ปี ${i + 543}","","","","","","","","","","",""`;
    //   _h_row_1 += `,"จำนวนทั้งหมด (ราย)","จำนวนตามเพศกำเนิด (ราย)","","ช่วงอายุ (ราย)","","","","","","","",""`;
    //   _h_row_2 += ',"","ชาย (ราย)","หญิง (ราย)","18 - 29 ปี","30 - 39 ปี","40 - 49 ปี","50 - 59 ปี","60 - 69 ปี","70 - 79 ปี","ERROR (อายุ 0 - 17 ปี)","ERROR (อายุมากกว่า 80 ปี)","ERROR (รวม)"';
    // }


    var _csv = [
      _h_row_0,
      _h_row_1,
      _h_row_2
    ];



    $('#d-register-table-by-year > tbody > tr').each(function () {
      var _row = [];
      $(this).find('td').each(function () {
        _row.push(`"${$(this).text()}"`);
      })

      _csv.push(`${_row.join(",")}`);
    })


    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent("\uFEFF" + _csv.join('\r\n')));
    a.setAttribute('download', `ข้อมูลทั่วไป-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`);
    window.document.body.appendChild(a);
    a.click();
  }

  campaignSelectCheckboxRender = () => {

    if($('.d-register-table-campaign-checkbox:checked').length === $('.d-register-table-campaign-checkbox').length) {
      $('#d-register-table-by-year-campaign-select-all').prop('checked', true);
    } else {

      $('#d-register-table-by-year-campaign-select-all').prop('checked', false);
    }
     
  }

  campaignSelectFilterRender = () => {
    var _filter = null;
    if($('#d-register-table-by-year-campaign-select-all').prop('checked') === true) {
      _filter = 'all'
    } else {
      _filter = [];

      $('.d-register-table-campaign-checkbox:checked').each(function() {
        _filter.push($(this).val())
      })
    }

    this.setState({filter: _filter}, ()=>{
      this.dataRender();
    });
  }

  campaignSelectRender = () => {
    var _row = [];

    for (var k in this.listByCampaignId) {
      _row.push(<label className='uk-display-block uk-text-small uk-text-normal '><input type="checkbox" className='uk-checkbox uk-margin-small-right d-register-table-campaign-checkbox' value={k} onChange={this.campaignSelectCheckboxRender}/> {this.listByCampaignId[k].label}</label>)
    }

    return <div class="uk-inline-block">
      <button class="uk-button" type="button" id="d-register-table-by-year-campaign-select-button">เลือกแคมเปญ <span uk-icon="chevron-down"></span></button>
      <div uk-dropdown="mode: click" className='uk-text-left' id="d-register-table-by-year-campaign-dropdown">
        <div>
          <label className='uk-display-block uk-text-small uk-text-normal'>
            <input type="checkbox" className='uk-checkbox uk-margin-small-right d-register-table-campaign-all-checkbox' id="d-register-table-by-year-campaign-select-all" value='all' onChange={(e) => {

              if($(e.target).prop('checked') === true) {
                $('.d-register-table-campaign-checkbox').prop('checked', true);
              } else {
                $('.d-register-table-campaign-checkbox').prop('checked', false);
              }
               
            }} /> ทั้งหมด
          </label>
        </div>
        <hr />
        <div className='uk-height-medium uk-overflow-auto'>
          {_row}
        </div>
        <div className='uk-text-right uk-margin-top'>
          <button className='uk-button uk-button-small uk-button-primary' onClick={this.campaignSelectFilterRender}>ยืนยัน</button>
        </div>
      </div>
    </div>
  }

  yearSelectRender = () => {

    var _start_year = 2022;
    var _now_year = moment().format('YYYY');

    var _option = [];

    for (var i = _start_year; i <= _now_year; i++) {
      _option.push(<option value={i}>{i}</option>)
    }

    _option.push(<option value="all">รวมทุกปี</option>)

    return <select value={this.state.year} className='uk-select uk-width-small uk-margin-left' onChange={(e) => {
      this.setState({
        year: e.target.value
      }, () => {
        this.dataRender();
      })
    }}>
      {_option}
    </select>
  }

  render() {
    return <div className="uk-card uk-card-default uk-table-striped uk-margin-bottom uk-margin-top uk-padding-small">
      <div class="d-card-header">
        <div className='uk-grid-smalluk-child-width-1-1 uk-child-width-1-2@m' uk-grid="">
          <div>ข้อมูลทั่วไป</div>
          <div className='uk-text-right'> 
            <div className='uk-flex uk-flex-middle uk-flex-right'>
              {
                this.campaignSelectRender()
              }
              {this.yearSelectRender()}
              <button className='uk-button d-text-white uk-button-primary uk-margin-left' onClick={this.CsvExport}><span uk-icon="download"></span> Export CSV</button>

            </div>
          </div>
        </div>
      </div>
      <div className='uk-overflow-auto'>
        <table id="d-register-table-by-year" className='uk-table uk-table-small uk-table-divider d-table-border-vertical uk-text-center'>
          <thead>
            {
              this.state.data_header
            }
          </thead>
          <tbody>

            {
              this.state.data_row
            }
          </tbody>
        </table>
      </div>
    </div>
  }
};
