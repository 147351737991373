
import React, { Component } from 'react'; 
import $ from 'jquery' 
import numeral from 'numeral';
import Api from "../Api.js";
import moment from 'moment';
import UIkit from 'uikit';
export default class SMCItemExpenseButton extends Component {

  state = {
    popup_show: false,
    list: [],
    loading_send: false
  }

  form = {
    name: '',
    amount: 0,
    inrightAmount: 0
  }

  form_dom = null

  componentDidMount() { 
    // console.log(this.props)
  }
 

  popup_show_setup = (is_show) => {
    this.setState({ popup_show: is_show });
  }

  list_add = () => {

    var _form = JSON.parse(JSON.stringify(this.form));

    this.setState({ list: [...this.state.list, _form] }, () => {
      this.form_dom.reset();

      this.form = {
        name: '',
        amount: 0,
        inrightAmount: 0
      }
    });
  }

  list_remove = (index) => {
    var _list = this.state.list;
    _list.splice(index, 1);
    this.setState({ list: _list.filter(Boolean) });
  }

  list_send  = async () => {
    this.setState({loading_send: true});
    var _detail = 'รายการค่าใช้จ่าย \n';
    this.state.list.forEach((l, index)=>{

      _detail += `${index+1}) ${l.name} : ยอด ${numeral(l.amount).format('0,0.00')} บาท (เบิกได้ ${numeral(l.inrightAmount).format('0,0.00')} บาท)\n`;
    }) 


    var response =  await $.ajax({
      method: 'POST',
      url: 'https://server.dietz.asia/openApi/monkmeesuk/v1.0.0/index.php',
      data: JSON.stringify({apiPath: 'payAlert', list: this.state.list, campaignId: this.props.data.campaignid, id: this.props.data.id }),
      error: function(e) {
        console.log(e)
      }
    })
    .promise();
 
    if(response.result === true) {
      
      var result = await Api.consult("ChatMessageAdd", {
        consultId: this.props.data.consultid,
        memberId: this.props.data.memberid,
        campaignId: this.props.data.campaignid,
        typeMessage: "visitnote",
        memberRead:0,
        consultRead: 1,
        chatby: 'c',
        visitNoteSymptomDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        visitNoteSymptomDetail: _detail,
        chatMessageVisitNoteHn: '',
        visitNoteSymptomImage: '',
      });

      this.popup_show_setup(false);
      
      if(typeof this.props.successCallback === 'function')
      this.props.successCallback();
    } else {
      UIkit.notification({ message: response.responseText });
    }
    
    this.setState({loading_send: false});
  }

  list_render = () => {
    if (this.state.list.length === 0) {
      return <div className='uk-padding uk-text-center'>ไม่มีรายการค่าใช้จ่าย โปรดเพิ่มรายการค่าใช้จ่าย</div>
    }
    else {
      var _sum = {
        amount: 0,
        inrightAmount: 0
      }

      return <div className='uk-margin-top-small'>
        <div>{this.state.list.map((item, index) => {
          _sum.amount += parseFloat(item.amount);
          _sum.inrightAmount += parseFloat(item.inrightAmount);

          return <div key={index} className='uk-margin-small-top uk-margin-small-bottom uk-text-left uk-grid uk-grid-small uk-child-width-1-4@m uk-child-width-1-2 d-border-bottom'>
            <div>{item.name}</div>
            <div>{item.amount}</div>
            <div>{item.inrightAmount}</div>
            <div className="uk-text-right">
              <a onClick={() => { this.list_remove(index) }} uk-icon="trash"></a>
            </div>
          </div>
        })}</div> 
        <div className='uk-margin-small-top uk-text-bold'>รวมค่าใช้จ่าย {numeral(_sum.amount).format('0,0.00')} บาท  เบิกได้ {numeral(_sum.inrightAmount).format('0,0.00')} บาท  ยอดชำระ {numeral(_sum.amount - _sum.inrightAmount).format('0,0.00')} บาท</div>
        <div className='uk-text-right uk-margin-top'>
          <button className='uk-button uk-button-default uk-margin-right' onClick={() => { this.popup_show_setup(false) }}>ยกเลิก</button>
          {
            this.state.loading_send === true ? 
            <button type="button" className='uk-button uk-button-primary'><span uk-spinner=""></span></button>
            :
            <button type="button" className='uk-button uk-button-primary' onClick={this.list_send}>ส่งรายการ</button>
          }
          
        </div>
      </div>
    }

  }

  form_add_render = () => {
    return <form ref={ref => { this.form_dom = ref }} className='uk-border uk-border-radius'>
      <div className='uk-text-left'><u>เพิ่มรายการค่าใช้จ่าย</u></div>
      <div className='uk-grid uk-grid-small uk-text-left uk-child-width-1-4@m uk-child-width-1-2'>
        <div>
          <label>ชื่อรายการ</label>
          <input type="text" className='uk-input' placeholder='ชื่อ' onChange={e => {
            this.form.name = e.target.value
          }} />
        </div>
        <div>
          <label>ยอดเงิน</label>
          <input type="number" min={0} className='uk-input' placeholder='0' onChange={e => {
            this.form.amount = e.target.value
          }} />
        </div>
        <div>
          <label>เบิกได้</label>
          <input type="number" min={0} className='uk-input' placeholder='0' onChange={e => {
            this.form.inrightAmount = e.target.value
          }} />
        </div>
        <div className='uk-text-right'>
          <br />
          <button type="button" className='uk-button uk-button-small uk-button-primary' onClick={this.list_add}>เพิ่ม</button>
        </div>
      </div>
    </form>
  }

  render() {
    return <><button className="uk-button uk-button-small uk-button-blue uk-margin-small-top" onClick={() => { this.popup_show_setup(true); }}>แจ้งค่าใช้จ่าย</button>
      {
        this.state.popup_show === true && <div className="d-popup-background">
          <div className="d-popup-body uk-width-2xlarge">
            <a uk-close="" className='uk-float-right' onClick={() => this.popup_show_setup(false)}></a>
            {this.form_add_render()}
            <div className='uk-margin-top uk-text-left'><u>รายการค่าใช้จ่าย</u></div>
            {this.list_render()}
          </div>
        </div>
      }

    </>
  }


}
